<template>
	<div>
		<v-dialog v-model="editMode" persistent max-width="600px">
			<template #activator="{ on }">
				<v-btn text rounded color="primary" v-on="on" @click="prepareDialogs()">
					<v-icon left> mdi-plus-circle </v-icon>
					{{ $t('studies.addNewDegree') }}
				</v-btn>
			</template>
			<v-card v-if="!confirmStudies" rounded="xl">
				<v-card-title>
					{{ $t('studies.addNewDegree') }}
					<v-spacer />
					<v-btn icon @click="closeDialogs()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-card-title>
				<v-card-text>
					<p class="text-body-1 text-justify" style="max-width: 95%">
						{{ $t('studies.setupHelp3') }}
					</p>
					<p class="text-body-1 text-justify" style="max-width: 95%">
						{{ $t('studies.setupHelp4') }}
					</p>
					<v-form>
						<v-autocomplete
							v-model="university"
							:label="$t('courses.university')"
							:placeholder="$t('studies.select')"
							:items="universities"
							item-text="name"
							item-value="id"
							chips
							return-object
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							@input="selectUniversity()"
						>
							<template v-slot:selection="data"> [{{ data.item.acronym }}] {{ data.item.name }} </template>
							<template v-slot:item="data"> [{{ data.item.acronym }}] {{ data.item.name }} </template>
						</v-autocomplete>
						<v-autocomplete
							v-if="university"
							v-model="faculty"
							:label="$t('courses.faculty')"
							:placeholder="$t('studies.select')"
							:items="faculties"
							item-text="name"
							item-value="id"
							chips
							return-object
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							@input="selectFaculty()"
						>
							<template v-slot:selection="data"> [{{ data.item.acronym }}] {{ data.item.name }} </template>
							<template v-slot:item="data"> [{{ data.item.acronym }}] {{ data.item.name }} </template>
						</v-autocomplete>
						<v-autocomplete
							v-if="university && faculty"
							v-model="degree"
							:label="$t('courses.degree')"
							:placeholder="$t('studies.select')"
							:items="degrees"
							item-text="name"
							item-value="id"
							chips
							return-object
							outlined
							rounded
							hide-details="auto"
							class="pb-3"
							@input="callNeedsRegistration()"
						>
							<template v-slot:selection="data"> [{{ data.item.acronym }}] {{ data.item.name }} </template>
							<template v-slot:item="data"> [{{ data.item.acronym }}] {{ data.item.name }} </template>
						</v-autocomplete>
					</v-form>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="confirmStudies" persistent max-width="600px">
			<v-card rounded="xl">
				<v-card-title>
					{{ setupInfo.accountType.alumni.value ? $t('settings.confirm') : $t('studies.studentEmail') }}
				</v-card-title>
				<template v-if="registrationRequired">
					<v-card-subtitle>{{ $t('studies.register') }} </v-card-subtitle>
					<v-card-text class="d-flex flex-column justify-end flex-grow-1 pb-1 flex-grow-1">
						<span class="text-body-1">{{ $t('studies.studyToAdd') }}</span>
						<span class="font-weight-bold text-body-1">{{ degree ? degree.name : '' }}</span>
						<v-chip-group column class="my-1">
							<v-chip>
								{{ faculty ? faculty.acronym : '' }}
							</v-chip>
							<v-chip>
								{{ university ? university.acronym : '' }}
							</v-chip>
						</v-chip-group>
						<v-checkbox v-model="studyEnded" :label="$t('studies.studyEnded')" />
						<span class="text-body-1">{{ $t('studies.studentEmailRequired') }}</span>
						<v-text-field v-model="studentEmail" :label="$t('auth.email')" :hint="$t('studies.studentEmailHelp')" persistent-hint type="text" />
					</v-card-text>
					<v-divider />
					<v-card-actions>
						<v-spacer />
						<v-btn color="secondary" text rounded @click="closeDialogs()">
							{{ $t('search.close') }}
						</v-btn>
						<v-btn color="primary" text rounded :loading="loadingAdding" :disabled="loadingAdding" @click="callRegisterStudent()">
							{{ $t('studies.register') }}
						</v-btn>
					</v-card-actions>
				</template>
				<template v-else>
					<v-card-text class="d-flex flex-column justify-end flex-grow-1 pb-1 flex-grow-1">
						<span class="text-body-1">{{ $t('studies.studyToAdd') }}</span>
						<span class="font-weight-bold text-body-1">{{ degree ? degree.name : '' }}</span>
						<v-chip-group column class="my-1">
							<v-chip>
								{{ faculty ? faculty.acronym : '' }}
							</v-chip>
							<v-chip>
								{{ university ? university.acronym : '' }}
							</v-chip>
						</v-chip-group>
						<v-checkbox v-model="studyEnded" :label="$t('studies.studyEnded')" />
						<template v-if="setupInfo.accountType.student.value">
							{{ $t('studies.willLinkedWith', { email: studentEmail }) }}
						</template>
					</v-card-text>
					<v-card-actions>
						<v-spacer />
						<v-btn color="secondary" text rounded @click="closeDialogs()">
							{{ $t('search.close') }}
						</v-btn>
						<v-btn color="primary" text rounded :loading="loadingAdding" :disabled="loadingAdding" @click="callRegisterStudentQuick()">
							{{ $t('settings.confirm') }}
						</v-btn>
					</v-card-actions>
				</template>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'DegreeAdd',
	props: {
		flat: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			editMode: false,
			confirmStudies: false,
			university: null,
			faculty: null,
			degree: null,
			registrationRequired: true,
			studentEmail: '',
			studyEnded: false,
			loadingAdding: false,
		}
	},
	computed: {
		...mapGetters({
			universities: 'universities/universities',
			faculties: 'faculties/faculties',
			degrees: 'degrees/degrees',
			setupInfo: 'user/setupInfo',
		}),
	},
	created() {
		this.fetchMinUniversities()
	},
	methods: {
		closeDialogs() {
			this.editMode = false
			this.confirmStudies = false
			this.university = null
			this.faculty = null
			this.degree = null
			this.registrationRequired = true
			this.studentEmail = ''
		},
		prepareDialogs() {
			if (this.university && this.faculty && this.degree) this.callNeedsRegistration()
			else {
				this.confirmStudies = false
				this.university = null
				this.faculty = null
				this.degree = null
				this.registrationRequired = true
				this.studentEmail = ''
			}
		},
		selectUniversity() {
			this.faculty = null
			this.degree = null
			this.confirmStudies = false
			this.fetchMinFacultiesFromUniversity(this.university)
		},
		selectFaculty() {
			this.degree = null
			this.confirmStudies = false
			this.fetchMinDegreesFromFacultyAndUniversity({ faculty: this.faculty, university: this.university })
		},
		callNeedsRegistration() {
			this.registrationRequired = true
			this.studentEmail = ''
			this.editMode = false
			this.needsRegistration({ facultyID: this.faculty.id, universityID: this.university.id })
				.then(({ success, registrationRequired }) => {
					if (success) {
						this.registrationRequired = registrationRequired
						if (!registrationRequired) {
							this.getStudentEmailFromFaculty()
						}
					}
				})
				.then(() => {
					this.confirmStudies = true
				})
		},
		getStudentEmailFromFaculty() {
			this.studentEmail = ''
			this.fetchStudentEmailFromFaculty(this.faculty).then(({ success, studentEmail }) => {
				if (success) {
					this.studentEmail = studentEmail
				}
			})
		},
		callRegisterStudent() {
			this.loadingAdding = true
			this.registerStudent({
				universityID: this.university.id,
				facultyID: this.faculty.id,
				degreeID: this.degree.id,
				studentEmail: this.studentEmail,
				studyEnded: this.studyEnded,
			})
				.then(({ success }) => {
					if (success) {
						this.closeDialogs()
					}
					this.fetchSetupInfo()
				})
				.then(() => {
					this.loadingAdding = false
					this.editMode = false
					this.confirmStudies = false
				})
		},
		callRegisterStudentQuick() {
			this.loadingAdding = true
			this.registerStudentQuick({
				universityID: this.university.id,
				facultyID: this.faculty.id,
				degreeID: this.degree.id,
				studentEmail: this.studentEmail,
				studyEnded: this.studyEnded,
			})
				.then(({ success }) => {
					if (success) {
						this.closeDialogs()
					}
					this.fetchSetupInfo()
				})
				.then(() => {
					this.loadingAdding = false
					this.editMode = false
					this.confirmStudies = false
				})
		},
		...mapActions('user', ['fetchSetupInfo']),
		...mapActions('universities', ['fetchMinUniversities']),
		...mapActions('faculties', ['fetchMinFacultiesFromUniversity']),
		...mapActions('degrees', ['fetchMinDegreesFromFacultyAndUniversity']),
		...mapActions('studies', ['needsRegistration', 'fetchStudentEmailFromFaculty', 'registerStudent', 'registerStudentQuick']),
	},
}
</script>

<style scoped></style>
